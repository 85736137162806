var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "email_verified-card",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          _vm.loading ? _c("v-card-loader") : _vm._e(),
          _vm.status === "valid"
            ? [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: {
                          autocomplete: "off",
                          transition: "slide-y-reverse-transition"
                        },
                        nativeOn: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleEnterKey($event)
                          }
                        },
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              row: "",
                              wrap: "",
                              "justify-center": "",
                              "gap-xs-3": ""
                            }
                          },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c("h1", { staticClass: "starling-h1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.verify.end.valid.title"
                                        )
                                      )
                                  )
                                ])
                              ]
                            ),
                            _vm._l(
                              _vm.$t("public.signup.verify.end.valid.content"),
                              function(ignored, index) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: index,
                                    attrs: { xs12: "", sm11: "", md10: "" }
                                  },
                                  [
                                    _c("p", {
                                      staticClass: "starling-body",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "public.signup.verify.end.valid.content[" +
                                              index +
                                              "]",
                                            { email: _vm.form.username }
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              }
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c("registration-field-password", {
                                  attrs: {
                                    confirmation: _vm.form.passwordConfirmation
                                  },
                                  on: { "form-validate": _vm.onFormValidate },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "password", $$v)
                                    },
                                    expression: "form.password"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c("registration-field-password-confirmation", {
                                  attrs: { password: _vm.form.password },
                                  on: { "form-validate": _vm.onFormValidate },
                                  model: {
                                    value: _vm.form.passwordConfirmation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "passwordConfirmation",
                                        $$v
                                      )
                                    },
                                    expression: "form.passwordConfirmation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "pa-0 pt-3" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: { xs12: "", "text-center": "", "pt-3": "" }
                          },
                          [
                            _c(
                              "primary-button",
                              {
                                ref: "verifyEmailBtn",
                                attrs: {
                                  id: "verify-email",
                                  type: "button",
                                  large: "",
                                  loading: _vm.loading,
                                  disabled: !_vm.valid || _vm.loading
                                },
                                on: { click: _vm.onVerifyEmail }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.valid.button"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.status === "invalid"
            ? [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm11: "", md10: "" } },
                          [
                            _c("h1", { staticClass: "starling-h1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.signup.verify.end.invalid.title"
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _vm._l(
                          _vm.$t("public.signup.verify.end.invalid.content"),
                          function(ignored, index) {
                            return _c(
                              "v-flex",
                              {
                                key: index,
                                attrs: { xs12: "", sm11: "", md10: "" }
                              },
                              [
                                _c("p", {
                                  staticClass: "starling-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.invalid.content[" +
                                          index +
                                          "]",
                                        { email: _vm.form.username }
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.status === "error"
            ? [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm11: "", md10: "" } },
                          [
                            _c("h1", { staticClass: "starling-h1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.signup.verify.end.error.title"
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _vm._l(
                          _vm.$t("public.signup.verify.end.error.content"),
                          function(ignored, index) {
                            return _c(
                              "v-flex",
                              {
                                key: index,
                                attrs: { xs12: "", sm11: "", md10: "" }
                              },
                              [
                                _c("p", {
                                  staticClass: "starling-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.error.content[" +
                                          index +
                                          "]",
                                        { email: _vm.form.username }
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.status === "expired"
            ? [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm11: "", md10: "" } },
                          [
                            _c("h1", { staticClass: "starling-h1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.signup.verify.end.expired.title"
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _vm._l(
                          _vm.$t("public.signup.verify.end.expired.content"),
                          function(ignored, index) {
                            return _c(
                              "v-flex",
                              {
                                key: index,
                                attrs: { xs12: "", sm11: "", md10: "" }
                              },
                              [
                                _c("p", {
                                  staticClass: "starling-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.expired.content[" +
                                          index +
                                          "]",
                                        { email: _vm.form.username }
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "pa-0 pt-3" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: { xs12: "", "text-center": "", "pt-3": "" }
                          },
                          [
                            _c(
                              "secondary-button",
                              {
                                ref: "resendLink",
                                attrs: {
                                  id: "resend-link",
                                  type: "button",
                                  large: "",
                                  loading: _vm.loading
                                },
                                on: { click: _vm.onResendCode }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.expired.button"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.status === "confirmed"
            ? [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm11: "", md10: "" } },
                          [
                            _c("h1", { staticClass: "starling-h1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.signup.verify.end.confirmed.title"
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _vm._l(
                          _vm.$t("public.signup.verify.end.confirmed.content"),
                          function(ignored, index) {
                            return _c(
                              "v-flex",
                              {
                                key: index,
                                attrs: { xs12: "", sm11: "", md10: "" }
                              },
                              [
                                _c("p", {
                                  staticClass: "starling-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.confirmed.content[" +
                                          index +
                                          "]",
                                        { email: _vm.form.username }
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "pa-0 pt-3" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: { xs12: "", "text-center": "", "pt-3": "" }
                          },
                          [
                            _c(
                              "secondary-button",
                              {
                                ref: "loginButton",
                                attrs: {
                                  id: "login",
                                  type: "button",
                                  large: "",
                                  loading: _vm.loading
                                },
                                on: { click: _vm.onLogin }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.verify.end.confirmed.button"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }