<template>
  <landing-central-card>
    <v-card flat class="email_verified-card" :elevation="elevation" :min-height="cardHeight" :style="cardStyles"
            :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="loading"></v-card-loader>
      <template v-if="status === 'valid'">
        <v-card-text class="pa-0">
          <v-form
              @keydown.enter.native="handleEnterKey"
              autocomplete="off"
              transition="slide-y-reverse-transition"
              ref="form"
              v-model="valid">
            <v-layout row wrap justify-center gap-xs-3>
              <v-flex xs12 sm11 md10>
                <h1 class="starling-h1"> {{ $t('public.signup.verify.end.valid.title') }}</h1>
              </v-flex>

              <v-flex xs12 sm11 md10 v-for="(ignored ,index) in $t('public.signup.verify.end.valid.content')"
                      :key="index">
                <p class="starling-body"
                   v-html="$t('public.signup.verify.end.valid.content['+index+']', {email:form.username})"></p>
              </v-flex>

              <v-flex xs12 sm11 md10>
                <registration-field-password v-model="form.password"
                                             :confirmation="form.passwordConfirmation"
                                             @form-validate="onFormValidate"/>
              </v-flex>

              <v-flex xs12 sm11 md10>
                <registration-field-password-confirmation v-model="form.passwordConfirmation"
                                                          :password="form.password"
                                                          @form-validate="onFormValidate"/>

              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0 pt-3">
          <v-layout row wrap justify-center align-center gap-xs-3>
            <v-flex xs12 text-center pt-3>
              <primary-button
                  id="verify-email"
                  ref="verifyEmailBtn"
                  type="button"
                  large
                  :loading="loading"
                  :disabled="!valid || loading"
                  @click="onVerifyEmail">
                {{ $t('public.signup.verify.end.valid.button') }}
              </primary-button>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </template>

      <template v-if="status === 'invalid'">
        <v-card-text class="pa-0">
          <v-layout row wrap justify-center gap-xs-3>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1"> {{ $t('public.signup.verify.end.invalid.title') }}</h1>
            </v-flex>

            <v-flex xs12 sm11 md10 v-for="(ignored ,index) in $t('public.signup.verify.end.invalid.content')"
                    :key="index">
              <p class="starling-body"
                 v-html="$t('public.signup.verify.end.invalid.content['+index+']', {email:form.username})"></p>
            </v-flex>
          </v-layout>
        </v-card-text>
      </template>

      <template v-if="status === 'error'">
        <v-card-text class="pa-0">
          <v-layout row wrap justify-center gap-xs-3>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1"> {{ $t('public.signup.verify.end.error.title') }}</h1>
            </v-flex>

            <v-flex xs12 sm11 md10 v-for="(ignored ,index) in $t('public.signup.verify.end.error.content')"
                    :key="index">
              <p class="starling-body"
                 v-html="$t('public.signup.verify.end.error.content['+index+']', {email:form.username})"></p>
            </v-flex>
          </v-layout>
        </v-card-text>
      </template>

      <template v-if="status === 'expired'">
        <v-card-text class="pa-0">
          <v-layout row wrap justify-center gap-xs-3>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1"> {{ $t('public.signup.verify.end.expired.title') }}</h1>
            </v-flex>

            <v-flex xs12 sm11 md10 v-for="(ignored ,index) in $t('public.signup.verify.end.expired.content')"
                    :key="index">
              <p class="starling-body"
                 v-html="$t('public.signup.verify.end.expired.content['+index+']', {email:form.username})"></p>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="pa-0 pt-3">
          <v-layout row wrap justify-center align-center gap-xs-3>
            <v-flex xs12 text-center pt-3>
              <secondary-button
                  id="resend-link"
                  ref="resendLink"
                  type="button"
                  large
                  :loading="loading"
                  @click="onResendCode">
                {{ $t('public.signup.verify.end.expired.button') }}
              </secondary-button>
            </v-flex>
          </v-layout>
        </v-card-actions>

      </template>

      <template v-if="status === 'confirmed'">
        <v-card-text class="pa-0">
          <v-layout row wrap justify-center gap-xs-3>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1"> {{ $t('public.signup.verify.end.confirmed.title') }}</h1>
            </v-flex>

            <v-flex xs12 sm11 md10 v-for="(ignored ,index) in $t('public.signup.verify.end.confirmed.content')"
                    :key="index">
              <p class="starling-body"
                 v-html="$t('public.signup.verify.end.confirmed.content['+index+']', {email:form.username})"></p>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="pa-0 pt-3">
          <v-layout row wrap justify-center align-center gap-xs-3>
            <v-flex xs12 text-center pt-3>
              <secondary-button
                  id="login"
                  ref="loginButton"
                  type="button"
                  large
                  :loading="loading"
                  @click="onLogin">
                {{ $t('public.signup.verify.end.confirmed.button') }}
              </secondary-button>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </template>
    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import { mapState } from 'vuex';
import RegistrationFieldPasswordConfirmation
  from '@/views/landing/register/components/registration-field-password-confirmation.vue';
import RegistrationFieldPassword from '@/views/landing/register/components/registration-field-password.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { VALIDATION_REGEX } from '@/scripts/api';
import SecondaryButton from '@/views/components/button/secondary-button.vue';
import { Auth } from 'aws-amplify';

export default {
  components: {
    SecondaryButton,
    PrimaryButton,
    RegistrationFieldPassword,
    RegistrationFieldPasswordConfirmation,
    LandingCentralCard,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      loading: false,
      valid: false,
      status: null,
      form: {
        uuid: null,
        username: null,
        code: null,
        password: null,
        passwordConfirmation: null,
      },
      loginRules: [
        v => (v && v.length > 0) || this.$t('common.errors.required'),
        v => VALIDATION_REGEX.EMAIL.test(v) || this.$t('common.errors.email'),
        v => (v && v.length <= 254) || this.$t('common.errors.max254'),
      ],
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
  },
  watch: {
    status(val) {
      if (val) {
        this.$router.replace({ query: { ...this.$route.query, status: this.status } });
      }
    },
  },
  mounted() {
    if (this.$route.query['username'] && this.loginRules.map(r => r(this.$route.query['username'])).every(r => r === true) && this.$route.query['code']) {
      this.form.uuid = this.$route.query['uuid'];
      this.form.code = this.$route.query['code'];
      this.form.username = this.$route.query['username'];
      if (this.$route.query['status']) {
        this.status = this.$route.query['status'];
      } else {
        this.status = 'valid';
      }
    } else {
      this.status = 'invalid';
    }
  },
  methods: {
    onFormValidate(fieldname) {
      if (this.$refs.form) {
        if (fieldname) {
          const field = this.$refs.form.$data.inputs.find(i => i.$attrs.name === fieldname);
          if (field) {
            field.validate(true);
          }
        } else {
          this.$refs.form.validate();
        }
      }
    },
    handleEnterKey() {
      if (this.valid) {
        this.onSignUp();
      }
    },
    onVerifyEmail() {
      this.loading = true;
      this.$store.dispatch('identity/verifyAccount', {
        uuid: this.form.uuid,
        username: this.form.username,
        password: this.form.password,
        code: this.form.code,
        clientMetadata: {
          subdomain: this.partner?.subdomain,
          locale: this.locale,
        },
      }).then(res => {
        if (res.type === 'expired') {
          this.status = 'expired';
          return;
        }
        if (res.type === 'confirmed') {
          this.status = 'confirmed';
          return;
        }
        if (res.type === 'error') {
          this.status = 'error';
          return;
        }
        return this.$store.dispatch('identity/login', {
          username: this.form.username,
          password: this.form.password,
        }).then(loginResult => {
          if (loginResult.passwordChange) {
            return this.$router.push({ name: 'new_password' });
          }
          if (!loginResult || !loginResult.userIdentity || !loginResult.userIdentity.location || !loginResult.userIdentity.location['@type']) {
            throw new Error('Cannot determine user');
          }
          if (loginResult.userIdentity.location['@type'] === 'nowhere') {
            return this.$store.dispatch('identity/checkReferral', { email: loginResult.userIdentity.username }).then(refIdentity => {
              if (refIdentity) {
                return this.$router.replace({
                  name: 'referral_register',
                  query: { referral_key: refIdentity.referralKey },
                });
              }
              return this.$router.replace({ name: 'setup_access_code' });
            });
          } else if (loginResult.userIdentity.location['@type'] === 'database') {
            if (!loginResult.redirect) {
              return this.$router.push({ name: 'home' });
            }
          }
        }, () => {
          this.status = 'error';
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    onLogin() {
      this.$router.replace({ name: 'sign_in', params: { email: this.form.username } });
    },
    onResendCode() {
      return Auth.resendSignUp(this.form.username, {
        subdomain: this.partner?.subdomain,
        locale: this.locale,
      }).then(() => {
        this.$store.state.auth.email_verification.username = this.form.username;
        return this.$router.push({ name: 'email_verification' });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.email_verified-card {
  border-radius: 15px;
}
</style>
